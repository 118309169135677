import { ICreateUser, IDeleteUser, IFetchGroups, IFetchUsers, IUpdateUser } from './IActions';
import keys from './ActionTypeKeys';
import {
	fetchUsers as fetchUserList,
	deleteUserById,
	handleCreateUser,
	handleUpdateUser,
	handleFetchGroups,
} from '../api/user';
import User from '../model/User';

export const fetchUsers = (): IFetchUsers => ({
	type: keys.FETCH_USERS,
	payload: fetchUserList(),
});

export const createUser = (user: User): ICreateUser => ({
	type: keys.CREATE_USER,
	payload: handleCreateUser(user),
});

export const updateUser = (user: User): IUpdateUser => ({
	type: keys.UPDATE_USER,
	payload: handleUpdateUser(user),
});

export const deleteUser = (id: string): IDeleteUser => ({
	type: keys.DELETE_USER,
	payload: deleteUserById(id),
});

export const fetchGroups = (): IFetchGroups => ({
	type: keys.FETCH_GROUPS,
	payload: handleFetchGroups(),
});
