import React from 'react';
import Spinner from '../accessories/Spinner';

const Loading = () => (
	<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
		<Spinner size={5} />
	</div>
);

export default Loading;
