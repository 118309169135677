import {
	Button,
	Card,
	CardActions,
	CardContent,
	Collapse,
	IconButton,
	makeStyles,
	styled,
	Tooltip,
} from '@material-ui/core';
import { Delete, ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { LicenceProps } from './LicensesManager';
import { useEffect, useState } from 'react';

const useStyles = makeStyles({
	card: {
		padding: '.5rem',
		margin: '.5rem 0',
	},
	listItem: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
		alignItems: 'center',
	},
	field: {
		padding: '.25rem',
	},
	editing: {
		width: 'fit-content',
		height: 'fit-content',
		minWidth: '5rem',
		padding: '.25rem',
		border: 'solid thin orange',
		whiteSpace: 'pre-line',
		outline: 'none',
	},
	textareaContainer: {
		display: 'flex',
		height: '25rem',
		overflow: 'auto',
		'& textarea': {
			fontSize: 'inherit',
			outline: 'none',
			border: 'solid thin orange',
			flex: 1,
		},
	},
	saveDiscardButtons: {
		'& button': {
			color: 'white',
			margin: '0 .5rem',
		},
	},
});

const ExpandMore = styled((props) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }: any) => ({
	transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
	marginLeft: 'auto',
	transition: theme.transitions.create('transform', {
		duration: theme.transitions.duration.shortest,
	}),
}));

type EditingFields = {
	[key: string]: boolean;
};

type DataFieldProps = {
	id: string;
	className: string;
	eventHandler: (event: any) => void;
	editing: boolean;
	content: string;
	placeholder?: string;
	useTextArea?: boolean;
};

const DataField = ({
	id,
	className,
	eventHandler,
	editing,
	content,
	placeholder,
	useTextArea = false,
}: DataFieldProps) => {
	const InputElement = useTextArea ? 'textarea' : 'input';
	return (
		<Tooltip placement="top" title={editing ? '' : 'Click to edit'}>
			{editing ? (
				<InputElement
					id={id}
					className={className}
					onBlur={eventHandler}
					onChange={eventHandler}
					tabIndex={0}
					autoFocus
					autoComplete="off"
					value={content}
				/>
			) : (
				<pre id={id} className={className} onClick={eventHandler} onFocus={eventHandler} tabIndex={0}>
					{content || placeholder}
				</pre>
			)}
		</Tooltip>
	);
};

type ListItemProps = {
	originalLicense: LicenceProps;
	handleSave: (licence: LicenceProps) => Promise<any>;
	handleDelete: (libraryName: string) => void;
};

const ListItem = ({ originalLicense, handleSave, handleDelete }: ListItemProps) => {
	const classes = useStyles();
	const [expanded, setExpanded] = useState(false);
	const [license, setLicense] = useState<LicenceProps>({ ...originalLicense });
	const [editingFields, setEditingFields] = useState<EditingFields>({});
	const [hasChanges, setHasChanges] = useState(false);

	const eventHandler = (event: any) => {
		event.preventDefault();
		const { target, type } = event;
		if (type === 'change') {
			setLicense((prev) => ({ ...prev, [target.id]: target.value }));
		} else {
			setEditingFields((prev) => ({ ...prev, [target.id]: type !== 'blur' }));
		}
	};

	useEffect(() => {
		setLicense(originalLicense);
	}, [originalLicense]);

	useEffect(() => {
		const check = JSON.stringify(originalLicense) !== JSON.stringify(license);
		setHasChanges(check);
	}, [license, originalLicense]);

	const { libraryName, licenseText, licenseVersion, services, source } = license;

	const save = () => {
		handleSave(license)
			.then(() => {
				setHasChanges(false);
			})
			.catch(() => {});
	};

	const discardChanges = () => {
		// @ts-ignore
		const confirmed = window.confirm('Are you sure you want to discard the changes?');
		if (confirmed) {
			setLicense({ ...originalLicense });
		}
	};

	return (
		<Card className={classes.card}>
			<CardContent>
				<div className={classes.listItem}>
					<DataField
						id="libraryName"
						className={editingFields.libraryName ? classes.editing : classes.field}
						eventHandler={eventHandler}
						editing={editingFields.libraryName}
						content={libraryName}
						placeholder="Library name"
					/>
					<DataField
						id="licenseVersion"
						className={editingFields.licenseVersion ? classes.editing : classes.field}
						eventHandler={eventHandler}
						editing={editingFields.licenseVersion}
						content={licenseVersion}
						placeholder="Version"
					/>
					<DataField
						id="services"
						className={editingFields.services ? classes.editing : classes.field}
						eventHandler={eventHandler}
						editing={editingFields.services}
						content={services}
						placeholder="Services"
					/>
					<DataField
						id="source"
						className={editingFields.source ? classes.editing : classes.field}
						eventHandler={eventHandler}
						editing={editingFields.source}
						content={source}
						placeholder="Source"
					/>
				</div>
				{!expanded && (
					<Tooltip placement="top" title="Double click to expand">
						<p
							className={classes.field}
							onDoubleClick={() => {
								setExpanded(true);
							}}
						>
							{licenseText.substring(0, 200) || 'License text'}...
						</p>
					</Tooltip>
				)}
			</CardContent>
			<Collapse in={expanded} timeout="auto" unmountOnExit>
				<CardContent>
					<div className={classes.textareaContainer}>
						<DataField
							id="licenseText"
							className={editingFields.licenseText ? classes.textareaContainer : classes.field}
							eventHandler={eventHandler}
							editing={editingFields.licenseText}
							content={licenseText}
							placeholder="License text"
							useTextArea={true}
						/>
					</div>
				</CardContent>
			</Collapse>
			<CardActions disableSpacing>
				{hasChanges ? (
					<div className={classes.saveDiscardButtons}>
						<Button onClick={save} size="small" variant="contained" color="primary">
							Save
						</Button>
						<Button onClick={discardChanges} size="small" variant="contained" color="secondary">
							Discard Changes
						</Button>
					</div>
				) : (
					<IconButton onClick={() => handleDelete(originalLicense.libraryName)}>
						<Delete />
					</IconButton>
				)}
				<ExpandMore expand={expanded} onClick={() => setExpanded((prev) => !prev)}>
					<ExpandMoreIcon />
				</ExpandMore>
			</CardActions>
		</Card>
	);
};

export default ListItem;
