import React from 'react';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { makeStyles, SvgIconTypeMap, TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';

const useStyles = makeStyles({
	inputContainer: {
		display: 'grid',
		position: 'relative',
		alignItems: 'flex-start',
		width: '100%',
		height: '20%',
		'& .MuiInputLabel-formControl': {
			left: '2rem',
		},
	},
	inputIcon: {
		position: 'absolute',
		display: 'flex',
		alignItems: 'flex-start',
		top: '1.32rem',
	},
});

export type TextFieldWithIconProps = {
	Icon: OverridableComponent<SvgIconTypeMap<any, 'svg'>>;
	iconOffset?: number;
} & TextFieldProps;

const TextFieldWithIcon = ({ Icon, iconOffset = 0.75, ...props }: TextFieldWithIconProps) => {
	const classes = useStyles();
	return (
		<div className={classes.inputContainer}>
			<div className={classes.inputIcon}>
				<Icon fontSize="small" />
			</div>
			<TextField {...props} inputProps={{ ...props.inputProps, style: { marginLeft: '2rem' } }} />
		</div>
	);
};

export default TextFieldWithIcon;
