import { Reducer } from 'redux';
import ActionTypes from '../actions/ActionTypes';
import initialState from '../store/initialState';
import keys from '../actions/ActionTypeKeys';
import CustomerState from '../model/CustomerState';

const customerInfo: Reducer<CustomerState, ActionTypes> = (state = initialState.customers, action): CustomerState => {
	switch (action.type) {
		case keys.FETCH_CUSTOMERS_RESOLVED:
			return { ...state, list: action.payload.data };
		case keys.DELETE_CUSTOMER_RESOLVED:
			return { ...state, list: state.list };
		default:
			return state;
	}
};

export default customerInfo;
