import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Link } from '@material-ui/core';
import { QueriesProps } from '../../api/auth';
import { Lock, LockOpen, Person } from '@material-ui/icons';
import Spinner from '../accessories/Spinner';
import PilabsButton from '../accessories/PilabsButton';
import TextFieldWithIcon from '../accessories/TextFieldWithIcon';
import PilabsCheckbox from '../accessories/PilabsCheckbox';

const useStyles = makeStyles((theme) => ({
	authenticator: {
		display: 'flex',
		position: 'relative',
		flexWrap: 'wrap',
		width: '20rem',
		alignItems: 'flex-end',
		height: '18rem',
		margin: 'auto',
		justifyContent: 'center',
	},
	header: {
		fontSize: '2rem',
		flexBasis: '100%',
		width: '100%',
		textAlign: 'center',
		alignSelf: 'flex-start',
		fontWeight: 600,
		margin: '0 auto 3rem auto',
	},
	inputs: {
		flexBasis: '100%',
		maxWidth: '100%',
	},
	footer: {
		flexBasis: '100%',
		maxWidth: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: 'fit-content',
	},
	link: {
		cursor: 'pointer',
		color: 'white',
	},
	subtitleText: {
		textAlign: 'center',
	},
	textFieldContainer: {
		width: '100%',
		lineHeight: '2px',
		display: 'grid',
		rowGap: '1rem',
		marginBottom: '.5rem',
	},
	buttonSpinnerAndText: {
		display: 'grid',
		gridTemplateColumns: '1.2rem auto',
		columnGap: '.2rem',
		alignItems: 'center',
	},
	privacyPolicyBox: {
		width: '100%',
		textAlign: 'left',
	},
	privacyPolicyLink: {
		color: 'var(--pilabs-blue)',
		textDecoration: 'none',
	},
}));

export enum AuthStates {
	SIGN_IN = 'signIn',
	FORGOT_PASSWORD = 'forgotPassword',
	RESET_PASSWORD = 'resetPassword',
	SET_NEW_PASSWORD = 'setNewPassword',
}

export type ResetProps = {
	username: string;
	code: any;
	password: string;
};

type AuthProps = {
	id: string;
	authState: AuthStates;
	submitButtonText: string;
	linkText: string;
	urlInfo: QueriesProps;
	title?: string;
	subtitleText?: string | null;
	redirectUri?: string;
	handleSignIn?: (username: string, password: string) => Promise<any>;
	handleForgotPassword?: (username: string) => void;
	handleResetPassword?: (props: ResetProps) => Promise<any>;
	handleSetNewPassword?: (username: string, password: string) => Promise<any>;
	handleStateChange?: () => void;
	loading?: boolean;
	needToAcceptTermsAndPolicy?: boolean;
	isAutoLogin: boolean;
};

const AuthForm = ({
	id="login_form",
	authState,
	submitButtonText,
	linkText,
	title = 'Login',
	subtitleText = null,
	urlInfo,
	handleSignIn,
	handleForgotPassword,
	handleResetPassword,
	handleStateChange,
	handleSetNewPassword,
	loading = false,
	needToAcceptTermsAndPolicy = false,
	isAutoLogin = false,
}: AuthProps) => {
	const classes = useStyles();

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [agreedPolicy, setAgreedPolicy] = useState(false);
	const [code, setCode] = useState('');
	const [passwordError, setPasswordError] = useState<string | null>(null);

	useEffect(() => {
		if (urlInfo.forgotPassword) {
			setUsername(urlInfo.username);
			setCode(urlInfo.code);
		}
		if (urlInfo.email && urlInfo.pass) {
			setUsername(decodeURIComponent(urlInfo.email));
		}
	}, [urlInfo]);

	const handleChangePasswordInput = ({ target }: any) => {
		if (target.name === 'password') {
			setPassword(target.value);
		} else {
			setConfirmPassword(target.value);
		}
		setPasswordError(null);
	};

	useEffect(() => {
		setAgreedPolicy(false);
	}, [authState]);

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (handleSignIn) {
			handleSignIn(username, password).finally(() => {
				setPassword('');
			});
		}
		if (handleForgotPassword) {
			handleForgotPassword(username);
		} else if (handleResetPassword) {
			if (password !== confirmPassword) {
				setPasswordError('Password and confirm password do not match');
				return;
			}
			handleResetPassword({ username, code, password })
				.then(() => {
					setCode('');
				})
				.catch((error) => {
					setPasswordError(error);
				})
				.finally(() => {
					setPassword('');
					setConfirmPassword('');
				});
		} else if (handleSetNewPassword) {
			if (password !== confirmPassword) {
				setPasswordError('Password and confirm password do not match');
				return;
			}
			handleSetNewPassword(username, password)
				.catch((error) => {
					setPasswordError(error);
				})
				.finally(() => {
					setPassword('');
					setConfirmPassword('');
				});
		}
	};

	const formFields = {
		signIn: (
			<>
				<TextFieldWithIcon
					Icon={Person}
					className={classes.inputs}
					label="E-Mail Address"
					value={username}
					inputProps={{ required: true }}
					onChange={({ target }) => setUsername(target.value)}
					type="email"
				/>
				<TextFieldWithIcon
					Icon={Lock}
					className={classes.inputs}
					label="Password"
					value={password}
					inputProps={{ required: true }}
					onChange={({ target }) => setPassword(target.value)}
					type="password"
				/>
			</>
		),
		forgotPassword: (
			<TextFieldWithIcon
				Icon={Person}
				className={classes.inputs}
				label="E-Mail Address"
				value={username}
				inputProps={{ required: true }}
				onChange={({ target }) => setUsername(target.value)}
				type="email"
			/>
		),
		resetPassword: (
			<>
				<TextFieldWithIcon
					Icon={LockOpen}
					className={classes.inputs}
					label="Code"
					value={code}
					inputProps={{ required: true }}
					onChange={({ target }) => setCode(target.value)}
				/>
				<TextFieldWithIcon
					Icon={Lock}
					className={classes.inputs}
					label="New password"
					value={password}
					iconOffset={0}
					error={Boolean(passwordError)}
					inputProps={{ required: true, name: 'password' }}
					helperText={passwordError ? passwordError : ' '}
					onChange={handleChangePasswordInput}
					type="password"
				/>
				<TextFieldWithIcon
					Icon={Lock}
					className={classes.inputs}
					label="Confirm password"
					value={confirmPassword}
					iconOffset={0}
					error={Boolean(passwordError)}
					inputProps={{ required: true, password: 'confirmPassword' }}
					helperText={passwordError ? passwordError : ' '}
					onChange={handleChangePasswordInput}
					type="password"
				/>
			</>
		),
		setNewPassword: (
			<>
				<TextFieldWithIcon
					Icon={Lock}
					className={classes.inputs}
					label="New password"
					value={password}
					inputProps={{ required: true, name: 'password' }}
					iconOffset={0}
					error={Boolean(passwordError)}
					helperText={passwordError ? passwordError : ' '}
					onChange={handleChangePasswordInput}
					type="password"
				/>
				<TextFieldWithIcon
					Icon={Lock}
					className={classes.inputs}
					label="Confirm password"
					value={confirmPassword}
					inputProps={{ required: true, password: 'confirmPassword' }}
					error={Boolean(passwordError)}
					helperText={passwordError ? passwordError : ' '}
					onChange={handleChangePasswordInput}
					type="password"
				/>
				{needToAcceptTermsAndPolicy ? (
					<PilabsCheckbox
						className={classes.privacyPolicyBox}
						onClick={() => {
							setAgreedPolicy(!agreedPolicy);
						}}
					>
						<>
							I agree with the{' '}
							<a
								target="_blank"
								rel="noreferrer"
								href={'/legal/privacy-policy.html'}
								className={classes.privacyPolicyLink}
							>
								privacy policy
							</a>
						</>
					</PilabsCheckbox>
				) : null}
			</>
		),
	};

	return (
		<form id={id} className={classes.authenticator} onSubmit={handleSubmit}>
			{authState === AuthStates.SIGN_IN && isAutoLogin ? (
				<Spinner size={5} />
			) : (
				<>
					<div className={classes.header}>{title}</div>
					{subtitleText && <div className={classes.subtitleText}>{subtitleText}</div>}
					<div className={classes.textFieldContainer}>{formFields[authState]}</div>

					<div className={classes.footer}>
						<PilabsButton
							type="submit"
							padding=".5rem 1.5rem .4rem .3rem"
							disabled={
								(authState === AuthStates.SET_NEW_PASSWORD &&
									needToAcceptTermsAndPolicy &&
									!agreedPolicy) ||
								loading
							}
						>
							<div className={classes.buttonSpinnerAndText}>
								<div>
									{loading && (
										<Spinner
											borderWidth=".15rem"
											primaryColor="#fefefe90"
											secondaryColor="white"
											size={1}
										/>
									)}
								</div>
								{submitButtonText}
							</div>
						</PilabsButton>
						<Link className={classes.link} onClick={handleStateChange}>
							{linkText}
						</Link>
					</div>
				</>
			)}
		</form>
	);
};

export default AuthForm;
