import { makeStyles, Paper, Tooltip } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { Person } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from '../../actions/AuthActions';
import PilabsButton from '../accessories/PilabsButton';
import IStore from '../../store/IStore';
import User from '../../model/User';
import { Link } from "react-router-dom";

const useStyles = makeStyles({
	profileMenuContainer: {
		position: 'absolute',
		display: 'grid',
		flexDirection: 'column',
		right: '1.5rem',
		top: '2rem',
		width: 'fit-content',
		height: 'fit-content',
		justifyContent: 'center',
		outline: 'none',
		padding: '1rem',
		animation: '$appear 1s ease',
	},
	profileMenu: {
		display: 'flex',
		justifyContent: 'center',
		minWidth: '10rem',
		minHeight: '3.75rem',
	},
	menuIcon: {
		display: 'flex',
		justifyContent: 'center',
		color: 'white',
		alignItems: 'center',
		height: '3rem',
		width: '3rem',
		borderRadius: '50%',
		background: 'var(--pilabs-blue)',
	},
	menuToggle: {
		height: '2rem',
		width: '2rem',
		'&:hover': {
			cursor: 'pointer',
			background: 'var(--pi-labs-dark-blue)',
		},
	},
	menuContent: {
		display: 'block',
		flexWrap: 'wrap',
		width: '11.5rem',
		height: 'fit-content',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		padding: '1rem 0',
	},
	logoutBtn: {
		height: 'fit-content',
		alignSelf: 'flex-end',
		width: '100%',
	},
	'@keyframes appear': {
		from: {
			opacity: 0,
		},
		to: {
			opacity: 1,
		},
	},
});

const ProfileMenu = () => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const menuContainerRef = useRef();

	const user = useSelector<IStore, User | null | undefined>((state) => state.user.info);
	const dispatch = useDispatch();

	useEffect(() => {
		if (open) {
			const el = menuContainerRef.current as any;
			el.focus();
		}
	}, [open, menuContainerRef]);

	const handleBlur = ({ currentTarget, relatedTarget }: any) => {
		if (!currentTarget?.contains(relatedTarget)) {
			setOpen(false);
		}
	};

	return (
		<>
			<div id="menu-toggle" onClick={() => setOpen(true)} className={`${classes.menuIcon} ${classes.menuToggle}`}>
				<Person style={{ pointerEvents: 'none' }} />
			</div>
			<Paper
				ref={menuContainerRef}
				elevation={3}
				className={classes.profileMenuContainer}
				style={{ display: open ? 'flex' : 'none' }}
				onBlur={handleBlur}
				tabIndex={0}
			>
				<div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
						<Link to={'/profile'}>
							<Tooltip title={user?.email || ''} placement="right-start">
								<div className={classes.menuIcon} style={{ fontSize: '1.5rem' }}>
									{user?.email[0].toUpperCase()}
								</div>
							</Tooltip>
						</Link>
				</div>
				<div className={classes.menuContent}>{/* Add text content here */}</div>
				<div className={classes.profileMenu}>
					<PilabsButton
						className={classes.logoutBtn}
						onClick={() => {
							dispatch(signOut());
						}}
					>
						Logout
					</PilabsButton>
				</div>
			</Paper>
		</>
	);
};

export default ProfileMenu;
