import { CognitoUser, CognitoUserPool, ICognitoUserData } from 'amazon-cognito-identity-js';
import authConfig from './AmplifyConfig';

class CognitoUserWithSession extends CognitoUser {
	Session: string;
	constructor(data: ICognitoUserData, Session: string) {
		super(data);
		this.Session = Session;
	}
}

const cognitoUser = (username: string, session: string): CognitoUser => {
	return new CognitoUserWithSession(
		{
			Username: username,
			Pool: new CognitoUserPool({ UserPoolId: authConfig.userPoolId, ClientId: authConfig.userPoolWebClientId }),
		},
		session,
	);
};

export default cognitoUser;
