import React from 'react';
import { Redirect, Route, Switch, RouteProps } from 'react-router-dom';
import Authenticator from '../auth/Authenticator';
import Profile from '../pages/Profile';
import CustomerManagement from '../customer/CustomerManagement';
import UserManagement from '../user/UserManagement';
import LegalRoutes from '@chaptesi/licenses/components/LegalRoutes';
import LicensesManager from '../licenses/LicensesManager';
import Licenses from '../licenses/Licenses';

// USER ROLES
const ROOT = 'Root';
const ADMIN = 'Admin';
const FLEET_MANAGER = 'FleetManager';
const FLEET_USER = 'FleetUser';
const TECHNICIAN = 'Technician';

export interface IRoutes {
	hide?: boolean;
	uri: string;
	label: string;
	roles: string[];
}

export type TRoutes = {
	[key: string]: IRoutes;
};

export const routes: TRoutes = {
	profile: {
		hide: true,
		uri: '/profile',
		label: 'Profile',
		roles: [ROOT, ADMIN, FLEET_MANAGER, FLEET_USER, TECHNICIAN],
	},
	userManagement: { uri: '/user-management', label: 'user management', roles: [ROOT, ADMIN, FLEET_MANAGER] },
	customerManagement: { uri: '/customer-management', label: 'customer management', roles: [ROOT, ADMIN] },
	thirdPartyLicensesManagement: {
		uri: '/licenses-management',
		label: '3rd-party licenses management',
		roles: [ROOT],
	},
};

export interface IRoute {
	userRoles: Array<string>;
}

interface IProtectedRoute extends RouteProps {
	redirect?: string;
	roles: string[];
	userRoles: string[];
}

interface IProppedRoute extends RouteProps {
	isSignedIn: boolean;
}

const ProtectedRoute = ({ roles, userRoles, component: C, redirect, ...rest }: IProtectedRoute) => {
	const isSignedIn = Boolean(userRoles.length);
	return (
		<Route
			{...rest}
			render={(props) => {
				return isSignedIn && redirect && roles.some((r) => userRoles.includes(r)) ? (
					<Redirect to={`/${redirect}`} />
				) : isSignedIn && roles.some((r) => userRoles.includes(r)) && C ? (
					<C {...props} />
				) : isSignedIn ? (
					<Redirect to="/" />
				) : (
					<Authenticator />
				);
			}}
		/>
	);
};

const ProppedRoute = ({ isSignedIn, component: C, ...rest }: IProppedRoute) => {
	return (
		<Route
			{...rest}
			render={(props) => {
				return isSignedIn && rest.location?.pathname === '/login' ? <Redirect to="/" /> : C && <C {...props} />;
			}}
		/>
	);
};

const Routes = (props: IRoute) => (
	<Switch>
		<ProppedRoute
			exact
			path={'/login'}
			component={Authenticator}
			isSignedIn={Boolean(props.userRoles.length)}
			{...props}
		/>
		<ProtectedRoute
			exact
			path={'/'}
			roles={routes.profile.roles}
			userRoles={props.userRoles}
			redirect={
				props.userRoles.some((item) => [ROOT, ADMIN, FLEET_MANAGER].includes(item))
					? 'user-management'
					: 'profile'
			}
		/>
		<ProtectedRoute
			exact
			path={'/profile'}
			roles={routes.profile.roles}
			userRoles={props.userRoles}
			component={Profile}
		/>
		<ProtectedRoute
			exact
			path={routes.userManagement.uri}
			roles={routes.userManagement.roles}
			userRoles={props.userRoles}
			component={UserManagement}
		/>
		<ProtectedRoute
			exact
			path={routes.customerManagement.uri}
			roles={routes.customerManagement.roles}
			userRoles={props.userRoles}
			component={CustomerManagement}
		/>
		<ProtectedRoute
			exact
			path={routes.thirdPartyLicensesManagement.uri}
			roles={routes.thirdPartyLicensesManagement.roles}
			userRoles={props.userRoles}
			component={LicensesManager}
		/>
		<Route exact path="/legal/licenses">
			<Licenses />
		</Route>
		<LegalRoutes />
	</Switch>
);

export default Routes;
