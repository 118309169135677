import { BASE_URL } from './constants';
import axios from './interceptors';

export const fetchLicenses = () => {
	return axios.get(`${BASE_URL}/licenses`).then((resp) => resp.data);
};

export const fetchLicensesPublic = async () => {
	return axios.get(`${BASE_URL}/licenses-public`).then((resp) => resp.data);
};

export const saveLicense = (content: any) => {
	return axios.post(`${BASE_URL}/licenses`, content).then((resp) => resp.data);
};

export const deleteLicense = (licenseId: number | string) => {
	return axios.delete(`${BASE_URL}/licenses/${licenseId}`).then((resp) => resp.data);
};
