import React, { useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	Button,
	DialogActions,
	DialogProps,
	DialogContentText,
	makeStyles,
} from '@material-ui/core';
import { createCustomer, fetchCustomers } from '../../actions/CustomerActions';
import { useDispatch, useSelector } from 'react-redux';
import IStore from '../../store/IStore';
import Spinner from '../accessories/Spinner';
import { checkForErrors } from '../shared/functions';

const useStyles = makeStyles({
	buttons: {
		fontWeight: 600,
	},
});

const REQUIRED = '* Required';

type StatePiece = {
	value: string;
	type: string;
	required: boolean;
	error?: string | null;
	maxLength?: number;
};

export interface IState {
	name: StatePiece;
	fleetManager: StatePiece;
}

type CustomerDialogProps = {
	handleClose: () => void;
} & DialogProps;

const initialState = {
	name: { value: '', type: 'text', required: true, maxLength: 100 },
	fleetManager: { value: '', type: 'email', required: true },
};

const CreateCustomerDialog = ({ handleClose, open }: CustomerDialogProps) => {
	const classes = useStyles();
	const [state, setState] = useState<IState>(initialState);
	const dispatch = useDispatch();
	const loading = useSelector<IStore, boolean>((state) => state.loading);

	const onClose = ({ currentTarget: target }: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		const result = checkForErrors(state);
		if (Object.keys(result).length > 0 && target.name === 'create') {
			setState((previous) => ({ ...previous, ...result }));
			return;
		}

		if (target.name !== 'create') {
			setState(initialState);
			handleClose();
			return;
		}

		dispatch<any>(
			createCustomer({
				id: null,
				name: state.name.value,
				fleetManager: state.fleetManager.value,
			}),
		)
			.then(() => {
				dispatch(fetchCustomers());
				setState(initialState);
				handleClose();
			})
			.catch((error: Error) => {
				if (error.message.toLocaleLowerCase().includes('name already exists')) {
					setState((previous) => ({
						...state,
						name: {
							...state.name,
							error: `A customer with name ${state.name.value} already exists`,
						},
					}));
				}
				if (error.message.toLocaleLowerCase().includes('fleetmanager already exists')) {
					setState((previous) => ({
						...state,
						fleetManager: {
							...state.fleetManager,
							error: `A user with name ${state.fleetManager.value} already exists`,
						},
					}));
				}
			});
	};

	const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
		setState((previous: any) => ({
			...previous,
			[target.name]: { ...previous[target.name], value: target.value, error: null },
		}));
	};

	return (
		<Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Create Customer</DialogTitle>
			<DialogContent>
				<DialogContentText>
					To create a new customer, please enter the name of the customer and the email of the fleet manager.
				</DialogContentText>
				<TextField
					name="name"
					margin="dense"
					label="Customer Name"
					type="text"
					value={state.name.value}
					onChange={handleChange}
					error={Boolean(state.name.error)}
					helperText={state.name.error || REQUIRED}
					fullWidth
				/>
				<TextField
					margin="dense"
					name="fleetManager"
					label="Fleet Manager Email"
					type="email"
					value={state.fleetManager.value}
					onChange={handleChange}
					error={Boolean(state.fleetManager.error)}
					helperText={state.fleetManager.error || REQUIRED}
					fullWidth
				/>
			</DialogContent>
			<DialogActions>
				{loading && <Spinner size={0.9} borderWidth=".15rem" />}
				<Button
					name="create"
					className={classes.buttons}
					onClick={onClose}
					color="primary"
					size="small"
					disabled={loading}
				>
					Create
				</Button>
				<Button name="cancel" className={classes.buttons} onClick={onClose} color="secondary" size="small">
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CreateCustomerDialog;
