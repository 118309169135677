import { BASE_URL } from './constants';
import axios from './interceptors';
import { Customer } from '../model/CustomerState';
import { AxiosError } from 'axios';
import errorHandler from './error-handler';

const CUSTOMER_BASE_URL = `${BASE_URL}/customers`;

export const fetchCustomers = () => {
	return axios.get(CUSTOMER_BASE_URL);
};

export const createNewCustomer = (customer: Customer) => {
	return axios
		.post(`${CUSTOMER_BASE_URL}`, customer)
		.then((response) => ({ ...customer, id: response.data }))
		.catch((error: AxiosError) => {
			throw errorHandler(error);
		});
};

export const deleteCustomerById = (id: string) => {
	return axios.delete(`${CUSTOMER_BASE_URL}/${id}`);
};
