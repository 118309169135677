import { BASE_URL } from './constants';
import axios from './interceptors';
import User from '../model/User';
import { AxiosError } from 'axios';
import handleError from './error-handler';

const USER_BASE_URL = `${BASE_URL}/users`;

export const fetchUsers = () => {
	return axios.get(USER_BASE_URL);
};

export const handleCreateUser = (user: User) => {
	return axios
		.post(USER_BASE_URL, { name: user.name, group: user.groups[0], email: user.email })
		.then((response) => ({ ...user, id: response.data }))
		.catch((error: AxiosError) => {
			throw handleError(error);
		});
};

export const handleUpdateUser = (user: User) => {
	return axios
		.put(`${USER_BASE_URL}/${user.username}`, { name: user.name, group: user.groups[0], email: user.email })
		.then((response) => ({ ...user, id: response.data }))
		.catch((error: AxiosError) => {
			throw handleError(error);
		});
};

export const deleteUserById = (id: string) => {
	return axios.delete(`${USER_BASE_URL}/${id}`);
};

export const handleFetchGroups = () => {
	return axios.get(`${BASE_URL}/users/groups`);
};
