import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Amplify from 'aws-amplify';
import Routes from './components/router/Routes';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import authConfig from './components/auth/AmplifyConfig';
import { useDispatch, useSelector } from 'react-redux';
import IStore from './store/IStore';
import User from './model/User';
import { signIn } from './actions/AuthActions';
import Loading from './components/pages/Loading';
import { fetchGroups } from './actions/UserActions';

Amplify.configure({
	Auth: authConfig,
});

function App() {
	const user = useSelector<IStore, User | null | undefined>((state) => state.user.info);
	const dispatch = useDispatch();

	useEffect(() => {
		user
			? dispatch(fetchGroups())
			: dispatch<any>(signIn()).catch((error: Error) =>
					toast.error(error.message, {
						autoClose: 3000,
						position: toast.POSITION.BOTTOM_LEFT,
					}),
			  );
	}, [dispatch, user]);

	const userRoles = user?.groups || [];

	return user === undefined ? (
		<Loading />
	) : (
		<div className="container">
			<Router>
				<Routes userRoles={userRoles} />
			</Router>
			<ToastContainer />
		</div>
	);
}

export default App;
