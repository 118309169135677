const ALLOWED_DOMAIN_PATTERN = /^https:\/\/([\w\d.-]+)?\.pi-labs\.eu(\/|$|\?)/;

/**
 * Redirect to an allowed URL.
 *
 * @param {string} url The URL of the page to open
 * @param {string} [target=_self] target The target attribute or the name of the window
 */
export function isValidDomain(url: string) {
	return ALLOWED_DOMAIN_PATTERN.test(url);
}
