import { combineReducers, Reducer } from 'redux';
import user from './AuthReducer';
import customers from './CustomerReducer';
import users from './UserReducer';
import loading from './LoadingReducer';
import IStore from '../store/IStore';
import ActionTypes from '../actions/ActionTypes';
import ActionTypeKeys from '../actions/ActionTypeKeys';
import initialState from '../store/initialState';

const appReducer = combineReducers<IStore>({ user, customers, users, loading });
const rootReducer: Reducer<IStore, ActionTypes> = (state = initialState, action): IStore => {
	if (action.type === ActionTypeKeys.SIGN_OUT_RESOLVED) {
		return { ...initialState, user: { info: null }, users: { ...initialState.users, groups: state.users.groups } };
	}
	return appReducer(state, action);
};

export default rootReducer;
