import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
	btnDisabled: {
		color: '#00baff70',
		pointerEvents: 'none',
	},
	checkmarkNotChecked: {
		display: 'inline-block',
		width: 13,
		height: 13,
		border: 'solid 1px gray',
		margin: '0 4px',
		backgroundColor: 'white',
	},
	checkmarkChecked: {
		display: 'inline-block',
		position: 'relative',
		width: 13,
		height: 13,
		border: 'solid 1px var(--pilabs-blue)',
		margin: '0 4px',
		backgroundColor: 'var(--pilabs-blue)',
		transition: 'background-color 250ms',
		'&::after': {
			top: 0.5,
			left: 3.5,
			width: 4.5,
			border: 'solid white',
			borderWidth: '0px 2.5px 2.5px 0',
			height: 7,
			content: '""',
			position: 'absolute',
			display: 'block',
			transform: 'rotate(45deg)',
		},
	},
	inputPILabs: {
		cursor: 'pointer',
		'& input': {
			backgroundColor: 'var(--pilabs-blue)',
			position: 'absolute',
			opacity: 0,
			cursor: 'pointer',
			height: 0,
			width: 0,
			'& :checked': {
				backgroundColor: 'red',
			},
		},
		'&:hover': {
			'& $checkmarkNotChecked': {
				border: 'solid 1px black',
			},
			'& $checkmarkChecked': {
				border: 'solid 1px var(--pi-labs-dark-blue)',
				backgroundColor: 'var(--pi-labs-dark-blue)',
			},
		},
	},
});

export type PLInputProps = {
	onClick?: (event?: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
	children?: JSX.Element | string | Array<JSX.Element | string>;
	disabled?: boolean;
	className?: string;
	textPosition?: string;
};

const PilabsCheckbox = ({ onClick, disabled = false, className, children, textPosition = 'right' }: PLInputProps) => {
	const classes = useStyles();
	const [isChecked, setIsChecked] = useState(false);

	return (
		<label className={`${classes.inputPILabs} ${className} ${disabled ? classes.btnDisabled : ''}`}>
			{textPosition.toLowerCase() === 'left' ? children : null}
			<input
				type={'checkbox'}
				className={classes.inputPILabs}
				onClick={onClick}
				disabled={disabled}
				onChange={(e) => {
					setIsChecked(e.target.checked);
					console.log(e.target.disabled);
				}}
			/>
			<span className={isChecked ? classes.checkmarkChecked : classes.checkmarkNotChecked}></span>
			{textPosition.toLowerCase() !== 'left' ? children : null}
		</label>
	);
};

export default PilabsCheckbox;
