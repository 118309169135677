import { Fragment, useEffect, useState } from 'react';
import { saveLicense, deleteLicense, fetchLicenses } from '../../api/licenses';
import Layout from '../layout/Layout';
import { Button, makeStyles } from '@material-ui/core';
import ListItem from './ListItem';
import { toast } from 'react-toastify';
import toastOptions from '../accessories/toast-options';
import Spinner from '../accessories/Spinner';

const useStyles = makeStyles({
	licenseManagement: {
		width: '100%',
		padding: '1rem 0',
	},
	licenseList: {
		width: '100%',
	},
	topActions: {
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		'& button': {
			color: 'white',
		},
	},
});

export interface LicenceProps {
	libraryName: string;
	licenseVersion: string;
	licenseText: string;
	services: string;
	source: string;
}

const LicensesManager = () => {
	const classes = useStyles();
	const [licenses, setLicenses] = useState<LicenceProps[]>([]);
	const [loading, setLoading] = useState(false);

	const handleSave = (license: LicenceProps) => {
		return saveLicense(license)
			.then(() => {
				toast.success('License successfully saved', toastOptions);
				fetchLicenses().then((data) => {
					setLicenses(data);
				});
			})
			.catch((error) => {
				toast.error('Error saving license.' + error.message, toastOptions);
			});
	};

	const discardNewLicense = () => {
		const _licenses = licenses.slice(1);
		setLicenses(_licenses);
	};

	const handleDelete = (libraryName: string) => {
		if (!libraryName) {
			return discardNewLicense();
		}
		// @ts-ignore
		const confirmed = window.confirm('Are you sure you want to delete this license?');
		if (!confirmed) return;
		deleteLicense(libraryName)
			.then(() => {
				toast.success('License successfully deleted', toastOptions);
				fetchLicenses().then((data) => {
					setLicenses(data);
				});
			})
			.catch((error) => {
				toast.error('Error deleting license.' + error.message, toastOptions);
			});
	};

	const addLicense = () => {
		if (!licenses[0].libraryName) return;
		const _licenses = [...licenses];
		_licenses.unshift({
			libraryName: '',
			services: '',
			source: '',
			licenseText: '',
			licenseVersion: '',
		});
		setLicenses(_licenses);
	};

	useEffect(() => {
		setLoading(true);
		fetchLicenses()
			.then((data) => {
				setLicenses(data);
			})
			.catch((error) => {
				toast.error('Error fetching licenses. ' + error.message, toastOptions);
			})
			.finally(() => setLoading(false));
	}, []);
	return (
		<Layout>
			<div className={classes.licenseManagement}>
				<div className={classes.topActions}>
					<Button onClick={addLicense} variant="contained" color="primary">
						Add License
					</Button>
				</div>
				<div className={classes.licenseList}>
					{licenses.length ? (
						licenses.map((license, index) => (
							<Fragment key={`${license.libraryName}_${index}`}>
								<ListItem
									originalLicense={license}
									handleSave={handleSave}
									handleDelete={handleDelete}
								/>
							</Fragment>
						))
					) : (
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							{loading ? <Spinner /> : 'No licenses'}
						</div>
					)}
				</div>
			</div>
		</Layout>
	);
};

export default LicensesManager;
